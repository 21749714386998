.portfolioImageContainer {
    height: 14.65vw;
    width: 14.65vw;
    margin: 1.5%;
    cursor: move;
    
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 12px;
        box-shadow: 0 8px 8px rgba(0, 0, 0, 0.4);
    }

    &:last-child {
        margin-right: auto;
    }
}