.homepageContainer {
    width: 100%;
    min-height: 100vh;
    .loadingLogo {
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;

        h1 {
            animation: grow-animation 5s linear infinite;
        }
        @keyframes grow-animation {
            0% { transform: scale(1); }
            50% {transform: scale(2); }
            100% {transform: scale(1); }
          }
    }

    .sectionsContainer {
        width: 100%;
        min-height: 100vh;
        display: flex;

        .servicesPagesContainer {
            width: 91vw;
            padding: 1.5%;
            display: flex;
            justify-content: center;
        }
    }
}