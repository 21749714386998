.individualImageContainer {
    width: 100%;
    padding: 4%;
    display: flex;
    background-color: rgba(97, 97, 97, 0.3);
    border-radius: 12px;

    .heroImageContainer {
        width: 40%;

        img {
            width: 100%;
            object-fit: cover;
        }

        .addedImagesContainer {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin-top: 20px;

            .addedImageSpecific {
                width: 80px;
                height: 80px;
                margin: 0 8px;
                cursor: pointer;
                .deleteSpecificImageContainer {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    background-color: #ffffffa3;
                    border-radius: 12px;
                }
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }
    }

    .individualImageInfoContainer {
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 4%;

        .langSwitch {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: end;

            .frLang,
            .enLang {
                color: white;
                margin: 0;
                margin: 0 8px;
                transition: 0.4s ease-in-out;
                cursor: pointer;
                font-size: 140%;
                &:hover {
                    color: orange;
                }

                &.activeLang {
                    color: orange;
                }
            }
        }

        h4 {
            text-align: center;
            color: white;
            font-size: 180%;
            display: flex;
            border-bottom: 1px solid white;
            padding: 1%;
            letter-spacing: 4px;
        }
        .individualImageInput {
            margin-top: 4%;
            width: 100%;

            .inputLabel {
                color: rgba(245, 245, 245, 0.8);
                font-size: 120%;
                font-weight: 400;
                margin-bottom: 1%;
            }

            .dimensionContainer {
                width: 100%;
                display: flex;
                .heightBox {
                    width: 45%;
                }
                .widthBox {
                    width: 45%;
                }
            }

            .dataSaveButton {
                display: flex;
                justify-content: end;
                margin-top: 40px;
                align-items: center;
                .spinner-border {
                    color: rgb(36, 97, 36);
                    margin-right: 8px;
                    
                }
                h5 {
                    color: rgb(75, 151, 75);
                    cursor: pointer;
                    transition: 0.4s ease-in-out;
                    margin: 0;

                    &:hover {
                        color: rgb(36, 97, 36);
                    }
                }

                .deleteButtonContainer {
                    cursor: pointer;
                    svg {
                        transition: 0.2s;
                        &:hover {
                            fill: #A73131;
                        }
                    }
                    .spinner-border {
                        color: #A73131;
                    }
                }
            }
        }
    }
}