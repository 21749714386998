.navigationContainer {
    width: 8vw;
    height: 100vh;
    background-color: rgb(250, 142, 41);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;

    .iconContainer {
        width: 80%;
        height: 40px;
        margin-top: 32%;
        padding: 1%;
        display: flex;
        justify-content: center;

        .icon {
            height: 100%;
            max-width: 90%;
        }
    }
}