.addImageButtonContainer {
    .addButton {
        width: 5vw;
        height: 5vw;
        border-radius: 50%;
        background-color: rgb(255, 194, 81);
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
        cursor: pointer;

        .spinner-border {
            color: white;
        }
        img {
            width: 50%;
            height: 50%;
        }
    }
    
}