.signInContainer {
    background-image: linear-gradient(rgb(252, 152, 70), rgb(194, 194, 194));
    min-height: 100vh;
    .logo {
        height: 200px;
    }

    .inputHolder {
        display: flex;
        flex-direction: column;
        align-items: center;
        label {
            text-transform: uppercase;
            margin: 2%;
            font-size: 140%;
        }
    }  
}