.editTextSectionContainer {
    width: 100%;
    .headerSection {
        display: flex;
        .langSwitch {
            margin-left: auto;
            margin-right: 5%;
            display: flex;
            align-items: center;
            justify-content: end;

            .frLang,
            .enLang {
                color: black;
                margin: 0;
                margin: 0 8px;
                transition: 0.4s ease-in-out;
                cursor: pointer;
                font-size: 140%;
                &:hover {
                    color: orange;
                }

                &.activeLang {
                    color: orange;
                }
            }
        }
    }
    .editSectionDivider {
        display: flex;
        justify-content: center;
        h2 {
            text-align: center;
        }
    }
}