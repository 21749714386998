.homeSectionContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

        .portfolioContainer {
            padding: 1%;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
            height: 100%;
            background-color: rgba(97, 97, 97, 0.3);
            border-radius: 12px;
            .saveOrderButton {
                justify-content: center;
                align-items: center;
                margin-left: auto;
                margin-right: 2%;
                margin-top: 2%;
                font-size: 1.8vw;
                color: rgb(236, 117, 37);
                text-transform: uppercase;
                font-weight: 600;
                transition: 0.6s;
                cursor: pointer;
                &:hover {
                    color: rgb(236, 161, 91);
                }
            }
        }
    
        .buttonContainer {
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: row;
            margin-bottom: 2%;
            padding: 1%;
        }

        .individualImageSelectors {
            width: 80%;
            display: flex;
            justify-content: center;
            margin: 10% auto 5% auto;
            flex-wrap: wrap;

            .imageSelector {
                width: 8vw;
                height: 8vw;
                margin: 1%;
                cursor: pointer;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 12px;
                    object-fit: cover;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
                    opacity: 0.5;
                    transition: 0.4s;

                    &.active {
                        opacity: 1;
                    }

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }

        .individualImageComponentContainer {
            width: 100%;
        }
}